import { navigate } from "@reach/router"
import React, { useEffect } from "react"
import Loading from "../components/Utils/Loading"
import { Container, Typography } from "@material-ui/core"

const NotFoundPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/manager")
    }, 3000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <div>
      <Container>
        <Typography>Having trouble finding page...</Typography>
        <Loading />
      </Container>
    </div>
  )
}

export default NotFoundPage
